import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Facebook } from './Facebook'
import { Twitter } from './Twitter'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export default class SEO extends Component {
  render() {
    const {
      title,
      desc,
      path = null,
      post,
      banner,
      yoast = [],
      location,
    } = this.props
    const {
      meta_robots_noindex,
      meta_robots_nofollow,
      twitter_image,
      opengraph_image,
      metaDescription,
    } = yoast
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressWpSettings {
              siteUrl
              wordpressUrl
              defaultTitle: title
              defaultDescription: description
              # defaultBanner: banner
              siteLanguage
              twitter
              shortName
            }
          }
        `}
        render={({
          wordpressWpSettings: {
            defaultTitle,
            siteUrl,
            defaultDescription,
            defaultBanner,
            siteLanguage,
            twitter = null,
            shortName,
          },
        }) => {
          const descriptionToUse = metaDescription
            ? metaDescription
            : defaultDescription
          const seo = {
            title: title || defaultTitle,
            description: desc || descriptionToUse,
            image: banner || defaultBanner,
            url:
              location && location.pathname && `${siteUrl}${location.pathname}`,
          }

          let schemaOrgJSONLD = [
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              '@id': siteUrl,
              url: siteUrl,
              name: defaultTitle,
            },
          ]

          if (post) {
            schemaOrgJSONLD = [
              {
                '@context': 'http://schema.org',
                '@type': 'BlogPosting',
                '@id': seo.url,
                url: seo.url,
                name: title,
                headline: title,
                image: {
                  '@type': 'ImageObject',
                  url: `${seo.image}`,
                },
                description: seo.description,
                datePublished: post.data,
                dateModified: post.data,
                author: {
                  '@type': 'Person',
                  name: defaultTitle,
                },
                publisher: {
                  '@type': 'Organization',
                  name: defaultTitle,
                  logo: {
                    '@type': 'ImageObject',
                    url: `/logos/logo-1200x630.jpg`,
                  },
                },
                isPartOf: siteUrl,
                mainEntityOfPage: {
                  '@type': 'WebSite',
                  '@id': siteUrl,
                },
              },
            ]
          }

          return (
            <>
              <Helmet title={seo.title}>
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={`${siteUrl}${seo.image}`} />
                <meta name="apple-mobile-web-app-title" content={shortName} />
                <meta name="application-name" content={shortName} />
                {meta_robots_noindex && (
                  <meta
                    name="robots"
                    content={`noindex${
                      meta_robots_nofollow ? ',nofollow' : ',follow'
                    }`}
                  />
                )}
                <script type="application/ld+json">
                  {JSON.stringify(schemaOrgJSONLD)}
                </script>
              </Helmet>
              <Facebook
                desc={seo.description}
                image={
                  opengraph_image && opengraph_image.source_url
                    ? `${siteUrl}${opengraph_image.source_url}`
                    : `${siteUrl}${seo.image}`
                }
                title={seo.title}
                type={post ? 'blog' : 'article'}
                url={seo.url}
              />
              <Twitter
                title={seo.title}
                image={
                  twitter_image && twitter_image.source_url
                    ? `${siteUrl}${twitter_image.source_url}`
                    : `${siteUrl}${seo.image}`
                }
                desc={seo.description}
                username={twitter}
              />
            </>
          )
        }}
      />
    )
  }
}
